.tech-dashboard {
  .tech-filters-wrap {
    display: flex;
    flex-wrap: wrap;

    >* {
      +* {
        margin-left: 10px;
      }
    }

    &__date {
      width: 275px;
    }
  }
  .tech-dashboard-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 20px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .tech-dashboard-item {
    background: #fff;
    padding: 15px;
    border-radius: 6px;
    border: 1px solid #f0f0f0;


    &__top {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 40px;
      margin-bottom: 10px;
      span {
        margin-left: 10px;
        padding-left: 10px;
        font-weight: bold;
      }
      img {
        width: 80px;
        height: 30px;
        object-fit: contain;
      }
    }
    &__list {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        border-top: 1px solid #f0f0f0;
        display: flex;
        line-height: 20px;
        padding: 5px 0;

        >* {
          &:first-child {
            font-size: 10px;
            color: gray;
            font-style: italic;
            width: 100px;
          }
          &:last-child {
            font-weight: bold;
            font-size: 12px;
          }
        }
      }
    }
  }

}