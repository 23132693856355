$primary_light: '';
$primary_main: #DC3751;
$primary_dark: '';

$secondary_main: #5826D1;
$secondary_dark: #2B0272;

$background_main: #3C059B;


$primary_gradient: linear-gradient(270deg, #DC3751 -0.05%, #DD38AC 99.95%);
$secondary_gradient: linear-gradient(90deg, #DC3751 0%, #5826D1 100%);