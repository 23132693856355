@mixin font-face($font-name, $file-name) {
  @font-face {
    font-family: quote($font-name);
    src:
    url($file-name + '.woff') format('woff'),
    url($file-name + '.woff2') format('woff2');
  }
}

@include font-face('Poppins-Regular', '../../fonts/poppins-400');
@include font-face('Poppins-Medium', '../../fonts/poppins-500');
@include font-face('Poppins-Bold', '../../fonts/poppins-600');