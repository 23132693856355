.admin-stats-grid {
  display: flex;
  margin: 0 -5px;
  padding-top: 10px;
  flex-wrap: wrap;

  .ant-card-body {
    padding: 10px;
  }

  &__item {
    flex: 0 0 25%;
    flex-grow: 1;
    padding: 0 5px;
    padding-bottom: 10px;

    @include media("<1440px") {
      flex: 0 0 50%;
      flex-grow: 1;
    }
    @include media("<768px") {
      flex: 0 0 100%;
      flex-grow: 1;
    }
  }
}

.admin-stats-card {
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.admin-stats-item {
  + * {
    border-top: 1px solid #c4c4c4;

  }

  display: flex;
  align-items: center;
  padding: 5px 0;
  font-size: 11px;

  > * {
    margin-bottom: 0;

  }

  &__number {
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: none;
    margin-right: 10px;
    font-size: 9px;
  }

  &__user {
    text-decoration: underline;

    &:hover {
      color: $primaryMain;
      text-decoration: none;
    }
  }

  &__value {
    margin-left: auto;
  }
}
.admin-stats-card {
  .ant-card-body {
    padding: 15px 24px;
  }
  &__top {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      margin-right: 15px;
    }
  }
}